import { IVeilederliste, IVeilederQuestion, IVeilederTabularRowCell, VeilederAnswerEnum } from '../shared/lib/types';
import { fieldHasMatch, markMatch } from '../shared/lib/filterUtils';

interface IQuestionValidator {
    type: VeilederAnswerEnum;
    message: string;
}
const heltall = 'Du må angi et heltall';
const desimaltall = 'Du må angi et desimaltall';

const validators: Array<IQuestionValidator> = [
    {
        type: VeilederAnswerEnum.number,
        message: heltall,
    },
    {
        type: VeilederAnswerEnum.integer,
        message: heltall,
    },
    {
        type: VeilederAnswerEnum.smallinteger,
        message: heltall,
    },
    {
        type: VeilederAnswerEnum.smallnumber,
        message: heltall,
    },
    {
        type: VeilederAnswerEnum.decimal,
        message: desimaltall,
    },
    {
        type: VeilederAnswerEnum.smalldecimal,
        message: desimaltall,
    },
    {
        type: VeilederAnswerEnum.double,
        message: desimaltall,
    },
    {
        type: VeilederAnswerEnum.text,
        message: 'Tekstfelt må fylles ut',
    },
    {
        type: VeilederAnswerEnum.date,
        message: 'Du må angi en dato',
    },
    {
        type: VeilederAnswerEnum.multiselect,
        message: 'Du må velge minst et alternativ',
    },
    {
        type: VeilederAnswerEnum.dropdown,
        message: 'Du må velge en verdi',
    },
];

const getValidationMessage = (type: VeilederAnswerEnum) => {
    return validators.find((x) => x.type === type)?.message;
};
export const getQuestionValidationResult = (questions: Array<IVeilederQuestion> = []) => {
    return questions.map((q) => {
        if (!q.questionOptional && !q.answerHidden && q.answerType !== VeilederAnswerEnum.tabular) {
            return validateQuestion(q);
        }
        if (!q.questionOptional && !q.answerHidden && q.answerType === VeilederAnswerEnum.tabular) {
            return validateTabularQuestion(q);
        }

        return q;
    }) as Array<IVeilederQuestion>;
};

function validateTabularQuestion(q: IVeilederQuestion): IVeilederQuestion {
    const rows = q.tabularValue.rows.map((row) => {
        for (let i = 0; i < row.cells.length; i++) {
            const cell = row.cells[i];
            const headerCell = q.tabularValue?.columns[i];

            cell.validationErrorMessage = '';

            if (isText(headerCell.type)) {
                if (!cell.stringValue) {
                    cell.validationErrorMessage = getValidationMessage(VeilederAnswerEnum.text);
                }
            }

            if (isDropdown(headerCell.type)) {
                if (!cell.stringValue) {
                    cell.validationErrorMessage = getValidationMessage(headerCell.type);
                }
            }

            if (isDate(headerCell.type)) {
                if (!cell.dateValue) {
                    cell.validationErrorMessage = getValidationMessage(headerCell.type);
                }
            }

            if (isNumberAnswer(headerCell.type)) {
                if (!isInt(cell.numberValue.toString())) {
                    cell.validationErrorMessage = getValidationMessage(headerCell.type);
                } else {
                    cell.numberValue = parseInt(row.cells[i].numberValue.toString());
                }
            }

            if (isDecimalAnswer(headerCell.type)) {
                if (!isDecimal(cell.doubleValue.toString())) {
                    cell.validationErrorMessage = getValidationMessage(headerCell.type);
                } else {
                    cell.doubleValue = parseFloat(row.cells[i].doubleValue.toString());
                }
            }
        }

        return row;
    });

    return {
        ...q,
        tabularValue: {
            ...q.tabularValue,
            row: rows,
        },
    } as IVeilederQuestion;
}

function validateQuestion(q: IVeilederQuestion): IVeilederQuestion {
    q.validationErrorMessage = '';

    if (isText(q.answerType)) {
        if (!q.stringValue) {
            q.validationErrorMessage = getValidationMessage(VeilederAnswerEnum.text);
        }
    }

    if (isMultiSelect(q.answerType) || isRadioButton(q.answerType)) {
        if (q.selectedValues.length === 0 && !q.stringValue) {
            q.validationErrorMessage = getValidationMessage(VeilederAnswerEnum.multiselect);
        }
    }

    if (isDate(q.answerType)) {
        if (!q.dateValue) {
            q.validationErrorMessage = getValidationMessage(q.answerType);
        }
    }

    if (isNumberAnswer(q.answerType)) {
        if (!isInt(q.numberValue.toString())) {
            q.validationErrorMessage = getValidationMessage(q.answerType);
        } else {
            q.numberValue = parseInt(q.numberValue.toString());
        }
    }

    if (isDecimalAnswer(q.answerType)) {
        if (!isDecimal(q.doubleValue.toString())) {
            q.validationErrorMessage = getValidationMessage(q.answerType);
        } else {
            q.doubleValue = parseFloat(q.doubleValue.toString());
        }
    }

    return q as IVeilederQuestion;
}

function isMultiSelect(type: VeilederAnswerEnum) {
    return type === VeilederAnswerEnum.multiselect;
}

function isRadioButton(type: VeilederAnswerEnum) {
    return type === VeilederAnswerEnum.radiobutton;
}

function isDropdown(type: VeilederAnswerEnum) {
    return type === VeilederAnswerEnum.dropdown;
}

function isText(type: VeilederAnswerEnum) {
    return isTextInput(type) || type === VeilederAnswerEnum.comment;
}

function isDate(type: VeilederAnswerEnum) {
    return type === VeilederAnswerEnum.date;
}

function isInt(value: any): boolean {
    let retValue = false;

    if (value !== null && value.length > 0 && !isNaN(value)) {
        const parsedValue = parseInt(value);

        retValue = !Number.isNaN(parsedValue) && parsedValue.toString() === value;
    }

    return retValue;
}

function isDecimal(value: any): boolean {
    let retValue = false;

    if (value !== null && value.length > 0 && !isNaN(value)) {
        retValue = !Number.isNaN(parseFloat(value));
    }

    return retValue;
}
// Update question value
export const valueChange = (questions: Array<IVeilederQuestion> = [], questionNumber: number, value: any) => {
    return questions.map((q) => {
        if (q.questionNumber === questionNumber) {
            if (isStringAnswer(q.answerType)) {
                q.stringValue = value;
            }
            if (q.answerType === VeilederAnswerEnum.multiselect) {
                q.selectedValues = value;
            }
            if (isNumberAnswer(q.answerType)) {
                if (!isInt(value)) {
                    q.validationErrorMessage = getValidationMessage(q.answerType);
                    q.numberValue = value;
                } else {
                    q.validationErrorMessage = '';
                    q.numberValue = parseInt(value);
                }
            }
            if (isDecimalAnswer(q.answerType)) {
                if (!isDecimal(value)) {
                    q.validationErrorMessage = getValidationMessage(q.answerType);
                    q.doubleValue = value;
                } else {
                    q.validationErrorMessage = '';
                    q.doubleValue = parseFloat(value);
                }
            }
            if (q.answerType === VeilederAnswerEnum.date) {
                q.dateValue = value;
            }
        }
        return q;
    }) as Array<IVeilederQuestion>;
};

// Update tabular question value
export const tabularValueChange = (
    questions: Array<IVeilederQuestion> = [],
    questionNumber: number,
    rowIndex: number,
    cellIndex: number,
    value: any
) => {
    return questions.map((q) => {
        if (q && q.questionNumber === questionNumber) {
            const cell = q.tabularValue?.rows[rowIndex].cells[cellIndex];
            const headerCell = q.tabularValue?.columns[cellIndex];

            if (cell && headerCell) {
                if (isStringAnswer(headerCell.type)) {
                    cell.stringValue = value;
                }
                if (isNumberAnswer(headerCell.type)) {
                    if (!isInt(value)) {
                        cell.validationErrorMessage = getValidationMessage(headerCell.type);
                        cell.numberValue = value;
                    } else {
                        cell.validationErrorMessage = '';
                        cell.numberValue = value;
                    }
                    cell.numberValue = value;
                }
                if (isDecimalAnswer(headerCell.type)) {
                    if (!isDecimal(value)) {
                        cell.validationErrorMessage = getValidationMessage(headerCell.type);
                        cell.doubleValue = value;
                    } else {
                        cell.validationErrorMessage = '';
                        cell.doubleValue = value;
                    }
                }
                if (headerCell.type === VeilederAnswerEnum.date) {
                    cell.dateValue = value;
                }
            }
        }
        return q;
    }) as Array<IVeilederQuestion>;
};

// Add a blank tabular answer row
export const tabularAddRow = (questions: Array<IVeilederQuestion> = [], questionNumber: number) => {
    return questions.map((q) => {
        if (q.questionNumber === questionNumber) {
            var columns = q.tabularValue?.columns.map(() => {
                return {
                    numberValue: 0,
                    stringValue: '',
                    cellDoubleValue: 0.0,
                    cellDateValue: new Date(),
                } as IVeilederTabularRowCell;
            }) as Array<IVeilederTabularRowCell>;

            q.tabularValue?.rows.push({ cells: columns });
        }
        return q;
    }) as Array<IVeilederQuestion>;
};

// Delete a tabular answer row
export const tabularDeleteRow = (questions: Array<IVeilederQuestion> = [], questionNumber: number, rowIndex: number) => {
    return questions.map((q) => {
        if (q.questionNumber === questionNumber) {
            q.tabularValue?.rows.splice(rowIndex, 1);
        }
        return q;
    }) as Array<IVeilederQuestion>;
};

// Count tabular answer rows
export const tabularRowCount = (questions: Array<IVeilederQuestion> = [], questionNumber: number) => {
    const q = questions.find((q) => q.questionNumber === questionNumber);
    return q?.tabularValue?.rows?.length || 0;
};

// Answer should be a stringAnswer
export const isStringAnswer = (type: VeilederAnswerEnum): boolean => {
    return (
        type === VeilederAnswerEnum.text ||
        type === VeilederAnswerEnum.string ||
        type === VeilederAnswerEnum.microtext ||
        type === VeilederAnswerEnum.minitext ||
        type === VeilederAnswerEnum.shorttext ||
        type === VeilederAnswerEnum.mediumtext ||
        type === VeilederAnswerEnum.longtext ||
        type === VeilederAnswerEnum.comment ||
        type === VeilederAnswerEnum.radiobutton ||
        type === VeilederAnswerEnum.dropdown ||
        type === VeilederAnswerEnum.alternatives ||
        type === VeilederAnswerEnum.undefined
    );
};

// Answer should be a numberAnswer (double and decimals might move)
export const isNumberAnswer = (type: VeilederAnswerEnum): boolean => {
    return (
        type === VeilederAnswerEnum.integer ||
        type === VeilederAnswerEnum.smallinteger ||
        type === VeilederAnswerEnum.number ||
        type === VeilederAnswerEnum.smallnumber
    );
};

export const isDecimalAnswer = (type: VeilederAnswerEnum): boolean => {
    return type === VeilederAnswerEnum.double || type === VeilederAnswerEnum.decimal || type === VeilederAnswerEnum.smalldecimal;
};
// Answer should be a text form control
export const isTextInput = (type: VeilederAnswerEnum): boolean => {
    return (
        type === VeilederAnswerEnum.string ||
        type === VeilederAnswerEnum.text ||
        type === VeilederAnswerEnum.microtext ||
        type === VeilederAnswerEnum.minitext ||
        type === VeilederAnswerEnum.shorttext ||
        type === VeilederAnswerEnum.mediumtext ||
        type === VeilederAnswerEnum.longtext
    );
};

const minQueryLength = 1;

export const filteredVeilederliste = (
    veilederliste: IVeilederliste,
    selectedVeilederType: string | undefined,
    searchQuery: string | undefined = undefined
): IVeilederliste => {
    let hovedKategorier = [...veilederliste.hovedKategorier];

    if (selectedVeilederType) {
        const filteredKategorier = hovedKategorier.map((kategori) => {
            const underKategorier = [...kategori.underKategorier];
            const filteredUnderKategorier = underKategorier.map((underKategory) => {
                const veiledere = [...underKategory.veiledere];
                const filteredVeiledere = veiledere.filter((veileder) => {
                    return veileder.type.toString() === selectedVeilederType;
                });
                return {
                    ...underKategory,
                    veiledere: filteredVeiledere,
                };
            });
            return {
                ...kategori,
                underKategorier: filteredUnderKategorier,
            };
        });
        hovedKategorier = filteredKategorier;
    }

    const qArr = searchQuery
        ?.split(' ')
        .filter((q) => q.length > minQueryLength)
        .map((q) => q.toLowerCase().trim());

    if (qArr) {
        const filteredKategorier = hovedKategorier.map((kategori) => {
            const underKategorier = [...kategori.underKategorier];
            const filteredUnderKategorier = underKategorier.map((underKategory) => {
                const veiledere = [...underKategory.veiledere];
                const filteredVeiledere = veiledere
                    .filter((veileder) => {
                        return fieldHasMatch(veileder.name.toLowerCase(), qArr);
                    })
                    .map((item) => {
                        return {
                            ...item,
                            name: markMatch(item.name, qArr),
                        };
                    });
                return {
                    ...underKategory,
                    veiledere: filteredVeiledere,
                };
            });
            return {
                ...kategori,
                underKategorier: filteredUnderKategorier,
            };
        });
        hovedKategorier = filteredKategorier;
    }

    return {
        ...veilederliste,
        hovedKategorier,
    };
};

const veilederHelper = {
    valueChange,
    tabularValueChange,
    tabularAddRow,
    tabularDeleteRow,
    tabularRowCount,
    isTextInput,
};

export default veilederHelper;
